body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Poppins-Light";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f8f9!important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.text-align-center .MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100%;
}

.text-align-center .MuiDataGrid-columnHeaderTitle {
  width: 100%;
}

.boldHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: 900;
}

.MuiDataGrid-menuIcon {
  display: none !important;
}

.height-100 .MuiBottomNavigation-root {
  height: auto;
}

.position-manangement-modal .MuiBox-root{
  height: 98vh;
  max-height: 98vh;
  padding: 15px 25px;
}
.hiddenButton {
  visibility: hidden;
  position: absolute;
  z-index: -999;
  width: 550px;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
}

/***** Tables body row ******/
.MuiTableBody-root .MuiTableRow-root {
  box-shadow: 0px 4px 12px rgba(112, 144, 176, 0.32);
  border-radius: 12px 12px 0px 0px;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
}

/* remove arrow in case of type numbers  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
input[type=number] {
  -moz-appearance: textfield;
}
.hiddenButton {
  visibility: hidden;
  position: absolute;
  z-index: -999;
  width: 550px;
}
